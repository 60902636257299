import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Platform, ActivityIndicator, ImageBackground, Dimensions } from 'react-native'

// import FeaturedCourseCard from '../../components/FeaturedCourseCard'
// import CardCourseStandard from '../../components/CardCourseStandard'
// import MyActiveCourses from '../../components/MyActiveCourses'


import LottieView from "lottie-react-native"

import CardActiveCourse from '../../components/CardActiveCourse';
import { FlatList } from 'react-native-gesture-handler';

import { firestore, storage } from 'firebase'
import AuthContext from '../../context/AuthContext';

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from '../../locale/translations'
import { Block, Element, Paragraph, Line } from '../../components/placeholders/MagicBox';

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;
const screenWidth = Dimensions.get('window').width;


const SmartHome = ({ id, company, navigation }) => {

    const { userData, tenant } = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [number, setNumber] = useState(1)

    useEffect(() => {
        setLoading(true)
        const unsubscribe = firestore().collection("users").doc(userData.id).collection("courseavs").where("visible", "==", true).where("tenant", "==", tenant).orderBy("initDate", "asc").onSnapshot((querySnapshot) => {
            setNumber(querySnapshot.docs.length)
            Promise.all(querySnapshot.docs.map(async (doc) => {
                const img = doc.data().image
                const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : null
                return { ...doc.data(), image: url, id: doc.id }
            })).then(res => {
                setData(res)
                setLoading(false)
            })
        })

        return () => { unsubscribe() }
    }, [])

    if (loading) {
        return (
            <View style={{ flexGrow: 1, paddingHorizontal: 10, paddingBottom: 20, width: "100%", maxWidth: 800, alignSelf: "center" }}>
                <Text style={{ textAlign: Platform.OS === "web" ? "left" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>{i18n.t('mycourses')}</Text>

                {Array.from(Array(number).keys()).map((el, i) =>
                    <Block key={i} card width="100%" margin={10}>
                        <Line margin={10} />
                        <Block width="100%" direction="row">
                            <Element height={120} width={120} />
                            <Block margin={10} justify="space-between" flex={1}>
                                <Line extraStyles={{ width: 150, alignSelf: "flex-end" }} />
                                <Line fontSize={22} extraStyles={{ width: 150 }} />

                            </Block>
                        </Block>
                    </Block>
                )}
            </View>
        )
    }
    return (
        data.length === 0 ?
            <View style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
                {Platform.OS !== "web" && <LottieView
                    autoPlay
                    loop={false}
                    style={{
                        width: 200,
                        height: 350,
                        marginBottom: 0
                    }}
                    source={require('../../assets/animations/courses.json')}
                />}
                <Text style={{ color: "darkgrey", fontSize: 20, textAlign: "center", marginHorizontal: 20, fontFamily: "DemiBold" }}>
                    {i18n.t('nocourses')}</Text>
            </View>
            :
            <FlatList
                data={data}
                showsVerticalScrollIndicator={false}
                extraData={data}
                loading={loading}
                ListHeaderComponent={() => data.length !== 0 && <Text style={{ textAlign: Platform.OS === "web" ? "left" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>{i18n.t('mycourses')}</Text>}
                contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 10, paddingBottom: 20, width: "100%", maxWidth: 800, alignSelf: "center" }}
                renderItem={({ item, index }) =>
                    <CardActiveCourse
                        key={index}
                        name={item.name}
                        description={item.description}
                        image={item.image}
                        data={item.advance}
                        initDate={item.initDate ? item.initDate.toDate() : false}
                        clickCallback={() => navigation.replace('CourseActive', { id: item.id })}
                    />
                }
                keyExtractor={(item, index) => item.id}
            />

    )
}

export default SmartHome;