import React, { useEffect, useState, useContext } from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, ScrollView, Platform, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { firestore, storage } from 'firebase';
import { FlatList } from 'react-native-gesture-handler';

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from '../../locale/translations'
import { useSafeArea } from 'react-native-safe-area-context';
import AuthContext from '../../context/AuthContext'

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;

const SocialAppraisal = ({ navigation }) => {

    const { customization, userData, tenant } = useContext(AuthContext)
    const insets = useSafeArea()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [rankingSelected, setRankingSelected] = useState(1)
    const [filtered, setFiltered] = useState([])
    const [search, setSearch] = useState("")
    const [usergroups, setUsergroups] = useState([])

    useEffect(() => {
        getUserGroups()
        getData()
    }, [])


    useEffect(() => {
        getData(rankingSelected)
    }, [rankingSelected])

    const getUserGroups = () => {
        Promise.all(userData.usergroups.map(ref => {
            return ref.get().then(doc => {
                const data = { id: doc.id, ...doc.data(), ref: doc.ref }
                return data
            })
        })).then(res => {
            setUsergroups(res)
        })
    }

    const getData = (usergroup) => {
        setLoading(true)
        if (usergroup && usergroup !== 1) {
            firestore().collection("users")
                .where("usergroups", "array-contains", usergroup)
                .orderBy("points", "desc").limit(10).get().then(async res => {
                    Promise.all(res.docs.map(async doc => {
                        const img = doc.data().image
                        const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg") : "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg"
                        return ({ ...doc.data(), image: url, id: doc.id, ref: doc.ref })
                    })).then(res => {
                        setData(res)
                        setFiltered(res)
                        setLoading(false)
                    })
                })
        } else {
            firestore().collection("users")
                .where("tenants", "array-contains", tenant)
                .orderBy("points", "desc").limit(10).get().then(async res => {
                    Promise.all(res.docs.map(async doc => {
                        const img = doc.data().image
                        const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg") : "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg"
                        return ({ ...doc.data(), image: url, id: doc.id, ref: doc.ref })
                    })).then(res => {
                        setData(res)
                        setFiltered(res)
                        setLoading(false)
                    })
                })
        }
    }

    return (
        <View style={{ flex: 1 }}>
            {/* <View style={{ backgroundColor: "#f0f0f0", paddingLeft: 5, alignItems: "center", flexDirection: "row", height: 50 }}>
                <TextInput onChangeText={(ev) => setSearch(ev)} placeholder="Buscar Personas..." style={{ paddingHorizontal: 10, height: 40, flex: 1, backgroundColor: "white", borderRadius: 7 }} />
                <TouchableOpacity style={{ width: 50, alignItems: "center" }}>
                    <Ionicons size={35} color="gray" name="md-search" />
                </TouchableOpacity>
            </View> */}
            <View style={{ padding: 15 }}>
                <Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, fontFamily: "Bold", fontSize: 34 }}>{i18n.t('ranking')}</Text>
            </View>
            <View style={{ height: 50 }}>
                <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 15, justifyContent: Platform.OS === "web" ? "center" : null, alignItems: "center" }}>

                    <TouchableOpacity onPress={() => setRankingSelected(1)} style={{ height: 50, paddingHorizontal: 10 }}>
                        <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: rankingSelected === 1 ? "black" : "gainsboro" }}>Global</Text>
                    </TouchableOpacity>

                    {usergroups.map((g, i) =>
                        <TouchableOpacity key={i} onPress={() => setRankingSelected(g.ref)} style={{ height: 50, paddingHorizontal: 10 }}>
                            <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: rankingSelected === g.ref ? "black" : "gainsboro" }}>{g.name}</Text>
                        </TouchableOpacity>
                    )}
                </ScrollView>
            </View>

            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, maxWidth: 800, width: "100%", alignSelf: "center", paddingHorizontal: 15, paddingBottom: 70 + insets.bottom }}>
                {loading ?
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <ActivityIndicator />
                    </View>
                    :
                    filtered.filter(d => d.name.toLowerCase().includes(search.toLowerCase()) || d.email.toLowerCase().includes(search.toLowerCase()) || d.surname.toLowerCase().includes(search.toLowerCase())).map((u, i) => {
                        return (
                            <TouchableOpacity disabled={u.id === userData.id} onPress={() => navigation.navigate("ValorationUser", { id: u.id })} key={i}
                                style={{
                                    borderWidth: u.id === userData.id ? 4 : 0,
                                    borderColor: u.id === userData.id ? customization.mainColor : "",
                                    marginVertical: 5,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    borderRadius: 15,
                                    paddingHorizontal: 10,
                                    paddingVertical: 15
                                }}>
                                <View style={{ justifyContent: "center", marginLeft: 5, marginRight: 10 }}>
                                    <Text style={{ fontFamily: "DemiBold", fontSize: 28 }}>{i + 1}.</Text>
                                </View>
                                <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
                                    <Image source={{ uri: u.image }} style={{ marginRight: 10, height: 50, width: 50, borderRadius: 25, backgroundColor: "gainsboro" }} />
                                    <View style={{ flex: 1, justifyContent: "center" }}>
                                        <Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{u.name} {u.surname}</Text>
                                        <Text style={{ fontFamily: "Regular", fontSize: 14, color: "gray" }}>{u.team}</Text>
                                    </View>
                                    <View style={{ padding: 10, backgroundColor: "#f8f8f8", borderRadius: 10, alignItems: "flex-end" }}>
                                        <Text style={{ fontFamily: "Regular", fontSize: 10, color: "gray" }}>Puntos</Text>
                                        <Text style={{ fontFamily: "DemiBold", fontSize: 22, color: customization.mainColor }}>{u.points || 0}</Text>
                                    </View>
                                    {/* <Ionicons size={35} color="gray" name="md-ribbon" /> */}
                                </View>
                            </TouchableOpacity>
                        )
                    })}
            </ScrollView>
        </View>
    );
}

export default SocialAppraisal;