import React, { useState, useEffect, useContext } from "react";
import {
    View,
    Text,
    RefreshControl,
    ActivityIndicator,
    StyleSheet,
    Alert
} from "react-native";

import CardPostUser from "./CardPostUser";
import CardPostUserPlaceholder from "../placeholders/CardPostUserPlaceholder";
import moment from "moment";
import CommunityEditor from "./CommunityEditor";
import LottieView from "lottie-react-native"
import { useNavigation } from '@react-navigation/native';
import { FlatList } from "react-native-gesture-handler";
import withLocalStorage from "../../utils/localStore";

import { firestore, storage } from 'firebase'
import AuthContext from "../../context/AuthContext";
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from '../../locale/translations'

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;

const CommunityFeed = ({ today, navigation }) => {


    const { userData } = useContext(AuthContext)

    const [loadingMore, setLoadingMore] = useState(false);
    const [isMoreContent, setIsMoreContent] = useState(true)
    const [deleting, setDeleting] = useState(null)
    const [deleted, setDeleted] = useState(null)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(true)
    const { navigate } = useNavigation()
    const [data, setData] = useState([])


    useEffect(() => {
        setLoading(true)
        const listener = firestore().collection("tenants").doc(tenant.id).collection("feed").orderBy("publishDate", "desc").limit(limit).onSnapshot((querySnapshot) => {
            Promise.all(querySnapshot.docs.map(async (doc) => {
                const img = doc.data().image
                const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : null
                const med = doc.data().media
                const mediaurl = med ? await storage().ref(med).getDownloadURL().then(res => res).catch(err => "") : null
                const userimg = doc.data().user.image
                const userimgurl = userimg ? await storage().ref(userimg).getDownloadURL().then(res => res).catch(err => "") : null
                return { ...doc.data(), image: url, media: mediaurl, id: doc.id, user: { ...doc.data().user, image: userimgurl } }

            })).then(res => {
                setData(res)
                setLoading(false)
                // setListeners([...listeners, listener])
            })
        })

        return () => listener()
    }, [])


    // const getMore = () => {
    //     firestore().collection("feed").orderBy("publishDate", "desc").startAt(last).limit(limit).get().then(data => {
    //         setLast(data.docs[data.docs.length - 1])
    //         const listener = firestore().collection("feed").orderBy("publishDate", "desc").limit(limit).onSnapshot((querySnapshot) => {
    //             Promise.all(querySnapshot.docs.map(async (doc) => {
    //                 const img = doc.data().image
    //                 const url = img ? await storage().ref(img).getDownloadURL().then(res=>res).catch(err => "") : null
    //                 const med = doc.data().media
    //                 const mediaurl = med ? await storage().ref(med).getDownloadURL() : null
    //                 const userimg = doc.data().user.image
    //                 const userimgurl = userimg ? await storage().ref(userimg).getDownloadURL() : null
    //                 return { ...doc.data(), image: url, media: mediaurl, id: doc.id, user: { ...doc.data().user, image: userimgurl } }

    //             })).then(res => {
    //                 // setData([...res])
    //                 setLoading(false)
    //                 setLast(res[res.length - 1])
    //                 setListeners([...listeners, listener])
    //             })
    //         })
    //     })
    // }

    useEffect(() => {
        setDeleting(null)
    }, [data])




    return (
        <FlatList
            style={{ height: "100%" }}
            data={data}
            showsVerticalScrollIndicator={false}
            extraData={data}
            // ListFooterComponent={() => !isMoreContent ? <Text style={{ textAlign: "center", fontSize: 22, color: "darkgray", marginVertical: 30, fontFamily: "DemiBold" }}>No hay más contenidos</Text> : loadingMore ? <ActivityIndicator color="gray" size="large" style={{ marginTop: 20 }} marginVertical={20} /> : <View />}
            ListHeaderComponent={
                <CommunityEditor id={userData.id} />
            }
            ListFooterComponent={
                loadingMore ?
                    <View style={{ height: 100, justifyContent: "center", alignItems: "center" }}>
                        <ActivityIndicator />
                    </View>
                    : !isMoreContent ?
                        <View style={{ height: 100, justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontFamily: "DemiBold", color: "gray" }} >No hay más contenidos</Text>
                        </View>
                        :
                        null
            }
            contentContainerStyle={{ paddingHorizontal: 10, maxWidth:600, width:"100%", alignSelf:"center", paddingTop: 10, paddingBottom: 100 }}
            renderItem={({ item, index }) =>
                <CardPostUser
                    style={styles.shadow}
                    key={item.id}
                    position={index}
                    title={item.title}
                    postID={item.id}
                    user={item.user}
                    publishDate={item.publishDate && item.publishDate.toDate()}
                    categories={item.categories}
                    media={item.media}
                    image={item.image}
                    deleted={deleted}
                    likes={item.likes}
                    // likeCallback={() => giveLike(item.id, item.likes)}
                    comments={item.comments}
                    content={item.content}
                    deleting={deleting}
                    commentCallback={() => navigation.replace("Comments", { id: item.id, feed: "feed" })}
                    deleteCallback={() => {
                        setDeleting(index)
                        firestore().collection("tenants").doc(tenant.id).collection("feed").doc(item.id).delete()
                    }}
                />
            }
            keyExtractor={(item, index) => index.toString()}
            ListEmptyComponent={() =>
                loading ?
                    <View style={{ flex: 1, }}>
                        <CardPostUserPlaceholder />
                        <CardPostUserPlaceholder withImage />
                        <CardPostUserPlaceholder />
                    </View>
                    : <View style={{ marginTop: 35, marginBottom: 15, height: 250, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ color: "gray", fontFamily: "DemiBold", fontSize: 16 }}>{i18n.t('nocontent')}</Text>
                    </View>
            }
            onEndReachedThreshold={0}
            onEndReached={() => {
                // data.length > 0 && getMore()
            }}
        />


    );
}

export default CommunityFeed;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },

    buttonTop: {
        backgroundColor: "white",
        borderColor: "#679BA2",
        width: "50%",
        height: 55,
        alignItems: "center",
        justifyContent: "center"
    },
    buttonTopText: {
        fontFamily: "DemiBold",
        fontSize: 18,
    },

    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 7,
        elevation: 12
    },

    drawer: {
        shadowColor: "#000000",
        shadowOpacity: 0.8,
        shadowRadius: 3,
        elevation: 2
    },
    main: { paddingLeft: 3 }
});
