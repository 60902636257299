import React, { useState, useContext, useEffect } from "react";
import { ActivityIndicator, TextInput, TouchableOpacity, KeyboardAvoidingView, View, Text, Image, ImageBackground } from "react-native";
import AuthContext from "../../context/AuthContext";
import * as Analytics from "expo-firebase-analytics";
import { Video } from "expo-av";
import Constants from 'expo-constants';


const login = ({ navigation }) => {
	const { authFunctions, userData, customization, error, logging } = useContext(AuthContext);

	const [form, setForm] = useState({
		identifier: "",
		password: "",
	});

	// console.log(Constants.manifest.extra.videoBackground, Constants.manifest.extra.loginLogo )

	const handleLogin = async () => {
		await Analytics.logEvent("Login", {
			name: "login",
			screen: "login",
			purpose: "Login into the app",
		});
		authFunctions.signIn({ identifier: form.identifier, password: form.password });
	};

	return (
		<KeyboardAvoidingView style={{ flex: 1, alignItems: "center", backgroundColor: "white", height: "100%" }} behavior="padding" enabled>
			{Constants.manifest.extra.videoBackground && <View style={{position: "absolute", filter: "hue-rotate(290deg)", zIndex: 1, height: "100%", width: "100%", top: 0, left: 0, right: 0, bottom: 0}}>
				<Video
					source={{ uri: "https://www.intrum.com/Videos/Intrum_VI_Hero_Animation_1920x1080.mp4" }}
					useNativeControls={false}
					shouldPlay={true}
					resizeMode="cover"
					isLooping={true}
					style={{ flex: 1,  height:"100%"}}
				/>
			</View>}
			<ImageBackground
				style={{
					flex: 1,
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					zIndex: 2,
				}}
				// source={{uri: "https://www.solvia.es/assets/statics/slider-home/image1.jpg"}}
				source={{uri: Constants.manifest.extra.videoBackground ? null : Constants.manifest.extra.loginBackground}}
				resizeMethod="resize">
				<View style={{ width: "100%", maxWidth: 600, flex: 1, alignItems: "center", paddingHorizontal: 20, justifyContent: "center" }}>
					<View style={{ height: 150, width: 300, marginBottom: 20 }}>
						{/* <Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: "https://www.athento.com/wp-content/uploads/2018/03/intrum.png" }} /> */}
						<Image style={{ resizeMode: "contain", height: 150, width: 300 }} source={{ uri: Constants.manifest.extra.loginLogo }} />
					</View>

					<TextInput
						textContentType="emailAddress"
						clearButtonMode="while-editing"
						autoCapitalize="none"
						placeholder="Email"
						keyboardType="email-address"
						placeholderTextColor={"gainsboro"}
						style={{
							borderColor: error ? "#E9312B" : "gainsboro",
							color: "black",
							height: 55,
							width: "100%",
							backgroundColor: "white",
							fontFamily: "Regular",
							fontSize: 18,
							padding: 15,
							borderRadius: 15,
							borderWidth: error ? 2 : 1,
							outlineWidth: 0,
						}}
						onChangeText={(text) => setForm({ ...form, identifier: text.toLowerCase().trim() })}
						value={form && form.identifier}
					/>
					<TextInput
						textContentType="password"
						autoCapitalize="none"
						placeholder="Contraseña"
						placeholderTextColor={"gainsboro"}
						style={{
							borderColor: error ? "#E9312B" : "gainsboro",
							color: "black",
							marginTop: 20,
							height: 55,
							width: "100%",
							backgroundColor: "white",
							fontFamily: "Regular",
							fontSize: 18,
							padding: 15,
							borderRadius: 15,
							borderWidth: error ? 2 : 1,
							outlineWidth: 0,
						}}
						onChangeText={(text) => setForm({ ...form, password: text })}
						value={form && form.password}
						secureTextEntry={true}
					/>

					<Text style={{ fontFamily: "Regular", marginTop: 15, marginBottom: 10 }}>{error && error.message}</Text>
					<TouchableOpacity
						style={{
							height: 50,
							marginTop: 5,
							paddingHorizontal: 50,
							borderRadius: 25,
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: "white",
						}}
						onPress={handleLogin}>
						{logging ? (
							<ActivityIndicator color={"black"} />
						) : (
							<Text
								style={{
									color: "black",
									fontSize: 18,
									fontFamily: "DemiBold",
								}}>
								Acceder
							</Text>
						)}
					</TouchableOpacity>

					<TouchableOpacity style={{ marginTop: 20 }} onPress={() => navigation.navigate("Lost")}>
						<Text style={{ color: "white", fontSize: 18, fontFamily: "DemiBold" }}>Olvidé mi contraseña</Text>
					</TouchableOpacity>
				</View>
			</ImageBackground>
		</KeyboardAvoidingView>
	);
};

export default login;
