import React, { useMemo, useState, useEffect, useContext } from "react";
import { View, Text, ScrollView, Dimensions, StyleSheet, Image, TouchableOpacity, Platform, FlatList, ImageBackground, StatusBar } from "react-native";

import URL from "../../constants/Servers";
import ImageRel from "../../components/common/ImageRel";
import HTML from "react-native-render-html";

import { firestore, storage } from "firebase";
import CardPost from "../../components/contentComponents/CardPost";
import AuthContext from "../../context/AuthContext";
import { TextInput } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";

let unsubscribeContents;

const categoria = ({ navigation, route }) => {
	const categoryID = route.params.id;

	const { userData, tenant } = useContext(AuthContext);

	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [data, setData] = useState({
		contents: [],
	});

	useEffect(() => {
		firestore()
			.collection("tenants")
			.doc(tenant.id)
			.collection("categories")
			.doc(categoryID)
			.get()
			.then(async (doc) => {
				const img = doc.data().image;
				const url = img
					? await storage()
							.ref(img)
							.getDownloadURL()
							.then((res) => res)
							.catch((err) => "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png")
					: "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png";
				const cat = { ...doc.data(), id: doc.id, image: url, contents: [], ref: doc.ref };
				// console.log({ cat })
				setData(cat);
				getContents(cat);
				navigation.setOptions({ title: "Categoría - " + cat.name });
			});
	}, [categoryID]);

	const getContents = (cat) => {
		cat.ref
			.collection("contents")
			.where("usergroups", "array-contains-any", userData.usergroups)
			.where("published", "==", true)
			.orderBy("publishDate", "desc")
			.get()
			.then((snapShot) => {
				Promise.all(
					snapShot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png")
							: "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png";
						const content = {
							...doc.data(),
							image: url,
							id: doc.id,
						};
						return content;
					})
				).then((contents) => {
					setData({ ...cat, contents });
					setLoading(false);
				});
			});
	};

	const CategoryHeader = useMemo(() => {
		return (
			<ImageRel
				background
				source={data.image}
				backgroundColor="transparent"
				// imageStyle={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 15 }}
				style={{ alignItems: "center", justifyContent: "center", minHeight: 100, paddingVertical: 50, marginBottom: 10 }}>
				<View
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						// borderBottomLeftRadius: 16, borderBottomRightRadius: 15,
						backgroundColor: "rgba(0,0,0,0.6)",
					}}
				/>
				<View style={{ maxWidth: 900, alignSelf: "center" }}>
					<Text style={{ marginHorizontal: 10, fontSize: 26, fontFamily: "DemiBold", color: "white", zIndex: 3 }}>{data.name}</Text>
					<View style={{ paddingHorizontal: 10 }}>
						<HTML baseFontStyle={{ fontFamily: "Regular", textAlign: "center", fontSize: 16, color: "white" }} html={data.description ? data.description : "<div/>"} />
					</View>
				</View>
				<View style={{ height: 50, width: "100%", alignItems: "center", marginTop: 10 }}>
					<View style={{ maxWidth: 900, height: 50, width: "100%" }}>
						<TextInput
							placeholder={"Buscar Contenidos..."}
							value={search}
							onChangeText={(text) => setSearch(text)}
							style={{ maxWidth: 900, fontFamily: "DemiBold", height: 50, width: "100%", paddingHorizontal: 10, paddingRight: 60, backgroundColor: "white", borderRadius: 5 }}></TextInput>
						<View style={{ position: "absolute", right: 0, width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
							<Ionicons name="search" color="grey" size={30} />
						</View>
					</View>
				</View>
			</ImageRel>
		);
	}, [search, data]);

	return (
		<View style={{ flex: 1 }}>
			<StatusBar barStyle="light-content" />
			<FlatList
				style={{ height: "100%" }}
				data={data.contents.filter((el) => el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()))}
				showsVerticalScrollIndicator={false}
				extraData={data.contents}
				numColumns={Platform.OS === "web" ? 2 : 1}
				ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
				contentContainerStyle={{ paddingBottom: 80, flexGrow: 1 }}
				ListHeaderComponent={CategoryHeader}
				columnWrapperStyle={Platform.OS === "web" && { alignSelf: "center", width: "100%", maxWidth: 900 }}
				renderItem={({ item, index }) => {
					return (
						<View style={{ flex: 1, maxWidth: 450 }}>
							<CardPost
								key={index}
								position={index}
								title={item.title}
								idpost={item.id}
								user={item.user}
								publishDate={item.publishDate && item.publishDate.toDate()}
								categories={[]}
								image={item.image}
								comments={item.comments}
								likes={item.likes}
								navigate={navigation.navigate}
								pressCallback={() => {
									navigation.navigate("Post", { id: item.ref.id });
									// Analytics.logEvent("postView", {
									// 	contentType: "post",
									// 	itemId: item.id,
									// 	method: "app",
									// });
								}}
							/>
						</View>
					);
				}}
				keyExtractor={(item, index) => index.toString()}
				ListEmptyComponent={() => (
					<View style={{ flex: 1, marginVertical: 80, justifyContent: "center", alignItems: "center" }}>
						<Text style={{ color: "darkgray", fontFamily: "DemiBold", fontSize: 22 }}>{loading ? "Cargando Contenidos..." : "No hay Contenidos"}</Text>
					</View>
				)}
			/>
		</View>
	);
};

export default categoria;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.8,
		shadowRadius: 10,
	},
	drawer: {
		shadowColor: "#000000",
		shadowOpacity: 0.8,
		shadowRadius: 3,
		elevation: 10,
	},
	main: { paddingLeft: 3 },
});
