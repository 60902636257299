import React, { useContext, useRef, useState, useEffect } from "react";
import { View, Text, ScrollView, Image, Dimensions, TouchableOpacity, Platform, PanResponder } from "react-native";

import HTML from "react-native-render-html";

import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";
import * as Analytics from "expo-firebase-analytics";

import PointsContext from "../../context/PointsContext";

import * as firebase from 'firebase';
import AuthContext from "../../context/AuthContext";

import MediaViewerManager from "../../components/common/MediaViewerManager";
import WebView from "react-native-webview";

const preview = { uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" };

const Post = ({ navigation, route }) => {
	const { userData, dimensions, tenant } = useContext(AuthContext);

	const postID = route.params.id;
	const { givePoints } = useContext(PointsContext);

	const { navigate } = useNavigation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [image, setImage] = useState(null);
	const [media, setMedia] = useState(null);
	const [liked, setLiked] = useState(false);
	const [embedded, setEmbedded] = useState(null);

	const loadP = { 0: { opacity: 1 }, 0.5: { opacity: 0.4 }, 1: { opacity: 1 } };

	useEffect(() => {
		setLoading(true);
		if (userData && postID && tenant) {
			tenant.collection("analytics").add({
				type: 'POST',
				userid: userData?.id,
				username: userData?.name,
				usersurname: userData?.surname,
				useremail: userData?.email,
				userimage: userData?.image,
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
				page: 'POST',
				pageId: postID,
				usergroups: userData?.usergroups
			})
		}
		tenant
			.collection("contents")
			.doc(postID)
			.onSnapshot((doc) => {
				const img = doc.data().image;
				img &&
					firebase.storage()
						.ref(img)
						.getDownloadURL()
						.then((res) => res)
						.catch((err) => "")
						.then((url) => setImage(url));

				const media = doc.data().media;
				media &&
					firebase.storage()
						.ref(media)
						.getDownloadURL()
						.then((url) => setMedia(url));

				doc.data().likes && doc.data().likes.includes(userData.id) && setLiked(true);

				setData(doc.data());

				navigation.setOptions({ title: 'Contenido - ' + doc.data().title })
			});
	}, []);

	useEffect(() => {
		if (data?.contentType === "EMBEDDED") {
			const a = new URL(data?.embedded);
			setEmbedded(a.searchParams.get("v"));
		}
	}, [data]);

	useEffect(() => {
		if (data?.title) {
			setLoading(false);
		}
	});

	const giveLike = async () => {
		if (liked) {
			firebase.firestore()
				.collection("tenants")
				.doc(tenant.id).collection("analytics").add({
					type: 'DISLIKE',
					userid: userData?.id,
					username: userData?.name,
					usersurname: userData?.surname,
					useremail: userData?.email,
					userimage: userData?.image,
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
					page: 'POST',
					pageId: postID,
					usergroups: userData?.usergroups
				})
			await Analytics.logEvent("dislike", {
				contentType: "post",
				itemId: postID,
				method: "app",
			});
			setLiked(!liked);
			firebase.firestore()
				.collection("tenants")
				.doc(tenant.id)
				.collection("contents")
				.doc(postID)
				.update({
					likesCount: firebase.firestore.FieldValue.increment(-1),
					likes: firebase.firestore.FieldValue.arrayRemove(userData.id),
				});
			firebase.firestore()
				.collection("users")
				.doc(userData.id)
				.update({
					likes: firebase.firestore.FieldValue.arrayRemove(postID),
				});
		} else {
			firebase.firestore()
				.collection("tenants")
				.doc(tenant.id).collection("analytics").add({
					type: 'LIKE',
					userid: userData?.id,
					username: userData?.name,
					usersurname: userData?.surname,
					userimage: userData?.image,
					useremail: userData?.email,
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
					page: 'POST',
					pageId: postID,
					usergroups: userData?.usergroups
				})
			await Analytics.logEvent("like", {
				contentType: "post",
				itemId: postID,
				method: "app",
			});
			setLiked(!liked);
			firebase.firestore()
				.collection("tenants")
				.doc(tenant.id)
				.collection("contents")
				.doc(postID)
				.update({
					likesCount: firebase.firestore.FieldValue.increment(1),
					likes: firebase.firestore.FieldValue.arrayUnion(userData.id),
				});
			firebase.firestore()
				.collection("users")
				.doc(userData.id)
				.update({
					likes: firebase.firestore.FieldValue.arrayUnion(postID),
				});
			// givePoints({
			// 	showPoints: true,
			// 	message: "Conseguiste 10 Puntos al dar like a este contenido",
			// 	points: 10,
			// });
		}
	};

	const panResponder = useRef(
		PanResponder.create({
			onStartShouldSetPanResponder: (evt, gestureState) => true,
		})
	).current;

	if (loading)
		return (
			<View style={{ flex: 1, marginTop: 7, borderRadius: 15, alignItems: "center", width: "100%", maxWidth: 900 }}>
				<View style={{ aspectRatio: 1, width: "100%", backgroundColor: "gainsboro", marginBottom: 30 }} />
				<View style={{ paddingHorizontal: 10 }}>
					{/* <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 50, borderRadius: 7, marginRight: 10, backgroundColor: "gainsboro" }} />
        <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, backgroundColor: "gainsboro" }} />
        <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, width: "90%", backgroundColor: "gainsboro" }} />
        <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, backgroundColor: "gainsboro" }} />
        <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, borderRadius: 7, marginTop: 10, marginLeft: 10, width: "80%", backgroundColor: "gainsboro" }} /> */}
				</View>
			</View>
		);

	return (
		<ScrollView contentContainerStyle={{ flexGrow: 1, maxWidth: 900, width: "100%", alignSelf: "center" }} showsVerticalScrollIndicator={false}>
			{data?.contentType === "EMBEDDED" ? (
				<>
					{!Platform.OS === "web" ? (
						<WebView
							// ref={this.webViewRef}
							allowsFullscreenVideo
							useWebKit
							// onLoad={this.webViewLoaded}
							allowsInlineMediaPlayback
							mediaPlaybackRequiresUserAction
							javaScriptEnabled
							scrollEnabled={false}
							source={{ uri: "https://surajmdurgad.medium.com/embedding-youtube-videos-in-a-react-native-app-8c556a18fd76" }}
							style={{ height: 405, width: "100%", alignSelf: "center" }}
						/>
					) : (
							<iframe
								style={{ alignSelf: "center" }}
								width='100%'
								height='405'
								src={`https://www.youtube.com/embed/${embedded}?rel=0`}
								frameBorder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen></iframe>
						)}
				</>
			) : (
					(media || image) && (
						<MediaViewerManager
							useNativeControls={true}
							maxHeight={600}
							type={data.contentType}
							url={media}
							cover={image}
							videoProps={{
								onPlaybackStatusUpdate: (ev) => null,
							}}
						/>
					)
				)}

			{/* {data.contentType === "VIDEO" && media && (
        <View style={{ ...contentSize, borderRadius: Platform.OS !== "web" ? 0 : 10, overflow: "hidden", marginTop: Platform.OS === "web" ? 20 : 0, backgroundColor: "black", }}>
          <Video
            collapsable={true}
            style={{ flex: 1 }}
            onReadyForDisplay={ev => getMediaHeight(ev)}
            useNativeControls={true}
            resizeMode="cover"
            source={media} // Can be a URL or a local file.
          />
        </View>
      )}
      {(data.contentType === "AUDIO") && media &&
        <AudioPlayer source={media} cover={image} />
      }
      {data.contentType === "DOCUMENT" && media &&
        <View {...panResponder.panHandlers}
          style={{ height: 400 }}>
          {Platform.OS !== "web" ?
            <PDFReader
              style={{ flex: 1 }}
              withScroll
              source={media}
            />
            :
            <object data={media.uri} type="application/pdf" width="100%" height={400}>
            </object>
          }
        </View>
      }
      {data.contentType === "TEXT" && (
        <Image
          style={{
            backgroundColor: "red",
            width: dimensions.width,
            aspectRatio: imageRatio,
            resizeMode: "cover"
          }}
          source={{ uri: image }}
        />
      )} */}

			{/* BOTONES LIKE Y COMENTAR */}
			<View
				style={{
					flexDirection: "row",
					marginTop: Platform.OS === "web" ? 20 : 0,
					height: 50,
					maxWidth: Platform.OS === "web" ? 400 : null,
					borderRadius: Platform.OS === "web" ? 25 : 0,
					backgroundColor: "#f2f2f2",
				}}>
				<TouchableOpacity
					onPress={() => giveLike()}
					// disabled={liking}
					style={{ flexDirection: "row", flex: 1, alignItems: "center", justifyContent: "center" }}>
					<Ionicons name={liked ? "ios-heart" : "heart-outline"} size={20} style={{ marginRight: 10, color: "#ef0000" }} />
					<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray" }}>{data.likes ? data.likes.length : 0} Likes</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => navigate("Comments", { id: postID, feed: "contents" })}
					style={{ flexDirection: "row", flex: 1, borderLeftWidth: 1, borderLeftColor: "gainsboro", alignItems: "center", justifyContent: "center" }}>
					<Ionicons name='chatbox' size={20} style={{ marginRight: 10, color: "gray" }} />
					<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray" }}>{data.comments ? data.comments.length : 0} Comentarios</Text>
				</TouchableOpacity>
			</View>

			{/* CUERPO */}
			<View
				style={{
					flex: 1,
					paddingHorizontal: 10,
					marginBottom: 20,
					maxWidth: 900,
				}}>
				<Text
					style={{
						fontSize: 30,
						marginVertical: 20,
						fontFamily: "DemiBold",
					}}>
					{data.title}
				</Text>

				{data.content !== "" && (
					<HTML
						baseFontStyle={{
							fontFamily: "Regular",
							fontSize: 16,
							color: "#303030",
						}}
						html={data.content ? data.content : "<p></p>"}
						onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
					/>
				)}
			</View>
		</ScrollView>
	);
};

Post.navigationOptions = {
	tabBarVisible: false,
};

export default Post;
