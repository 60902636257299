import React, { useEffect, useState, useContext, useRef } from "react";

import { Ionicons } from "@expo/vector-icons";
import * as firebase from "firebase";
import { CurrentRenderContext, Link, useNavigation } from "@react-navigation/native";
import { View, StyleSheet, Image, ScrollView, Text, TouchableOpacity, Linking, ActivityIndicator, Platform } from "react-native";

import AuthContext from "../../context/AuthContext";
import LinearBarIndicator from "../../components/LinearBarIndicator";
import ContentHome from "../stackContent/ContentHome";

import MediaViewerManager from "../../components/common/MediaViewerManager";
import WebView from "react-native-webview";
import Exam from "./Exam";
import PointsContext from "../../context/PointsContext";

import { firestore } from "firebase";

import * as Animatable from "react-native-animatable";

import HTML from "react-native-render-html";
import TestMission from "../../components/itineraryComponents/TestMission";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";

const ActivityGroup = ({ route }) => {
	const navigation = useNavigation();

	const { userData, customization, tenant } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);

	const { pointsContext, dispatch, state } = useContext(PointsContext);

	const [userResponse, setUserResponse] = useState(null);

	const [sectionContents, setSectionContents] = useState([]);

	const [currentContent, setCurrentContent] = useState(0);

	const { id, index } = route.params;

	const testContent = {
		content: {
			question: sectionContents[currentContent]?.contentDetails?.question || "",
			answers: sectionContents[currentContent]?.contentDetails?.answers || [],
		},
	};

	useEffect(() => {
		setLoading(true);
		tenant
			.collection("courses")
			.doc(id)
			.get()
			.then(async (res) => {
				const d = res.data();
				let final = d.contentDetails[index]
					? await Promise.all(
						d.contentDetails[index].contents.map(async (content) => {
							const conts = await content.ref.get().then(async (doc) => {
								if (doc.exists) {
									const d = doc.data();
									console.log(d);
									const img = d.image;
									const url = img
										? await firebase
											.storage()
											.ref(img)
											.getDownloadURL()
											.then((res) => res)
											.catch((err) => "")
										: null;
									const media = d.media;
									const mediaUrl = media
										? await firebase
											.storage()
											.ref(media)
											.getDownloadURL()
											.then((res) => res)
											.catch((err) => "")
										: null;
									let topic;

									if (d.contentType === "FORUM") {
										topic = await d.contentDetails.topicRef
											.get()
											.then((res) => ({ ...res.data(), id: res.id }))
											.catch((err) => console.log(err));
									}
									return { id: doc.id, ...d, image: url, topic, media: mediaUrl };
								} else {
									return {};
								}
							});
							return conts;
						})
					).then((res) => res)
					: [];
				setLoading(false);
				setSectionContents(final);
				setCurrentContent(0);
			});
	}, [id, index]);

	const uploadData = {
		content: {
			description: "descripción",
			seconds: 400000,
		},
	};

	const handleAdvance = () => {
		// comparar array advance del usuario con arrayAdvance del curso
		// añadir si falta el contenido al array de avance del usuario;
		const completed = sectionContents[currentContent];
		let newarr = state?.advance;
		if (newarr?.findIndex((advance) => completed.id === advance.id)) {
			newarr = [...newarr, completed];
			dispatch({ type: "ADVANCE", advance: newarr });
			console.log("dispatching event");
			state?.advanceRef?.update({ advance: firestore.FieldValue.arrayUnion(...newarr) });
		}
	};

	const onSectionComplete = () => {
		handleAdvance();
		const sectionPoints = parseInt(sectionContents[currentContent]?.score);
		const { achievements } = customization;
		const { givePoints } = pointsContext;
		const completedSectionPoints = {
			points: sectionPoints || "0",
			showPoints: true,
			message: "Sección completada",
		};
		givePoints(completedSectionPoints, achievements);
	};

	const nextContent = () => {
		if (sectionContents.length - 1 === currentContent) {
			// onSectionComplete();
			navigation.navigate("StackSmartNew", { screen: "SmartHome" });
			// setTimeout(() => {
			// 	navigation.goBack();
			// }, 500);
		} else {
			// onSectionComplete();
			setCurrentContent((currentContent) => currentContent + 1);
		}
	};

	if (loading) {
		return null;
	}

	return (
		// Render de secciones
		<View style={{ flexDirection: "row", maxWidth: 1200, width: "100%", alignSelf: "center", flex: 1 }}>
			<View style={{ width: "30%", zIndex: 3 }}>
				<View style={{ height: 60, alignItems: "center", flexDirection: "row" }}>
					<TouchableOpacity onPress={() => navigation.navigate("StackSmartNew", { screen: "SmartHome" })} style={{ alignItems: "center", flexDirection: "row" }}>
						<Ionicons name="arrow-back" style={{ marginRight: 10 }} size={30} />
						<Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>Volver</Text>
					</TouchableOpacity>
				</View>
				{/* grupo de actividades del path */}
				{sectionContents.map((content, i) => {
					return (
						<Animatable.View animation="fadeInUp" duration={200} delay={i * 200} key={i}>
							<TouchableOpacity
								style={[
									styles.shadow,
									{ marginTop: 10, borderRadius: 7, borderRightColor: "orange", backgroundColor: "white", transform: [{ scale: currentContent === i ? 1.05 : 1 }], borderRightWidth: currentContent === i ? 5 : 0, width: "100%", flexDirection: "row", alignItems: "center" },
								]}
								onPress={() => setCurrentContent(i)}>
								<Image style={{ width: 90, height: "100%", borderRadius: 7, resizeMode: "cover" }} source={{ uri: content.image ? content.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
								<View style={{ paddingVertical: 20, flex: 1, alignItems: "center", paddingHorizontal: 15 }}>
									<Text style={{ fontFamily: "DemiBold", fontSize: 16, marginBottom: 20 }}>{content?.title}</Text>
								</View>
							</TouchableOpacity>
						</Animatable.View>
					);
				})}
			</View>
			{/* Actividad a mostrar */}

			{sectionContents[currentContent] ? (
				<View style={{ height: "100%", width: "70%" }}>
					<View style={{ flex: 1, width: "100%" }}>
						<ScrollView contentContainerStyle={{ maxWidth: 900, width: "100%", alignSelf: "center", paddingBottom: 50 }} showsVerticalScrollIndicator={false}>
							<View style={[styles.shadow, { zIndex: 1, backgroundColor: "white", height: "100%", borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }]}>
								<View style={{ padding: 15, height: "100%" }}>
									<View style={{ flex: 5 }}>
										{!(sectionContents[currentContent]?.contentType === "EMBEDDED" || sectionContents[currentContent]?.contentType === "VIDEO") && (
											<Image
												style={{ width: "100%", minHeight: 300, borderRadius: 7, resizeMode: "cover" }}
												source={{ uri: sectionContents[currentContent]?.image ? sectionContents[currentContent]?.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }}
											/>
										)}

										{!sectionContents[currentContent]?.contentType === "UPLOAD" && (
											<MediaViewerManager
												uploadData={sectionContents[currentContent]?.contentType ? uploadData : ""}
												useNativeControls={true}
												maxHeight={600}
												type={sectionContents[currentContent]?.contentType}
												url={sectionContents[currentContent]?.media}
												cover={sectionContents[currentContent]?.image}
												videoProps={{ onPlaybackStatusUpdate: (ev) => console.log(ev) }}
											/>
										)}

										<Text style={{ fontFamily: "Bold", fontSize: 34, marginTop: 20, marginBottom: 10 }}>{sectionContents[currentContent]?.title}</Text>

										{sectionContents[currentContent]?.contentType === "DOCUMENT" && (
											<MediaViewerManager
												uploadData={sectionContents[currentContent]?.contentType}
												useNativeControls={true}
												maxHeight={600}
												type={sectionContents[currentContent]?.contentType}
												url={sectionContents[currentContent]?.media}
												cover={sectionContents[currentContent]?.image}
												videoProps={{ onPlaybackStatusUpdate: (ev) => console.log(ev) }}
											/>
										)}

										{(sectionContents[currentContent]?.contentType === "VIDEO" || sectionContents[currentContent]?.contentType === "EMBEDDED") && (
											<MediaViewerManager
												useNativeControls={true}
												maxHeight={600}
												type={sectionContents[currentContent]?.contentType}
												url={sectionContents[currentContent]?.contentType === "EMBEDDED" ? sectionContents[currentContent]?.embedded : sectionContents[currentContent]?.media}
												cover={sectionContents[currentContent]?.image}
												videoProps={{ onPlaybackStatusUpdate: (ev) => console.log() }}
											/>
										)}

										<HTML
											style={{ height: "auto", width: "90%" }}
											baseFontStyle={{ fontFamily: "Regular", margin: 0, fontSize: 14, color: "#303030" }}
											html={sectionContents[currentContent]?.content ? sectionContents[currentContent]?.content : "<p></p>"}
											onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
										/>

										{(sectionContents[currentContent]?.contentType === "WEBINAR" || sectionContents[currentContent]?.contentType === "EVENT") && (
											<>
												<View style={{ marginTop: 20, marginBottom: 20 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>Información de la sesión</Text>
												</View>

												<View style={{ marginLeft: 15 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>Fecha y Enlace</Text>
													<View style={{ flexDirection: "row" }}>
														<View style={{ alignItems: "center", alignSelf: "flex-start", height: 50, paddingHorizontal: 20, marginBottom: 15, borderRadius: 10, backgroundColor: "#f3f3f3", justifyContent: "center", flexDirection: "row" }}>
															<Ionicons name="calendar-outline" size={30} style={{ marginRight: 10 }} />
															<Text style={{ fontFamily: "DemiBold", fontSize: 18 }} onPress={() => (Platform.OS === "web" ? window.open(sectionContents[currentContent]?.link, "_blank") : Linking.openURL(sectionContents[currentContent]?.link))}>
																{moment(sectionContents[currentContent]?.contentDetails?.date).format("DD [de] MMMM [de] YYYY [a las] hh:mm")}
															</Text>
														</View>

														<View style={{ marginLeft: 10, alignItems: "center", alignSelf: "flex-start", height: 50, paddingHorizontal: 20, marginBottom: 15, borderRadius: 10, backgroundColor: "#f3f3f3", justifyContent: "center", flexDirection: "row" }}>
															<Ionicons name="link" size={30} style={{ marginRight: 10 }} />
															<Text style={{ fontFamily: "DemiBold", fontSize: 18 }} onPress={() => (Platform.OS === "web" ? window.open(sectionContents[currentContent]?.link, "_blank") : Linking.openURL(sectionContents[currentContent]?.link))}>
																Ir a la Sala
															</Text>
														</View>
													</View>

													<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>{`Asistirás a este ${sectionContents[currentContent]?.contentType === "WEBINAR" ? "Webinar?" : "Evento?"}`}</Text>
													<View style={{ flexDirection: "row", marginTop: 10 }}>
														<TouchableOpacity style={{ height: 40, width: 170, marginRight: 10, alignItems: "center", borderRadius: 7, backgroundColor: "#afd6e2", justifyContent: "center" }}>
															<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>No</Text>
														</TouchableOpacity>
														<TouchableOpacity style={{ height: 40, width: 170, marginRight: 10, alignItems: "center", borderRadius: 7, backgroundColor: "#450045", justifyContent: "center" }}>
															<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Quizás</Text>
														</TouchableOpacity>
														<TouchableOpacity style={{ height: 40, width: 170, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center" }}>
															<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Si</Text>
														</TouchableOpacity>
													</View>
												</View>
											</>
										)}

										{sectionContents[currentContent]?.contentType === "LINK" && (
											<>
												<Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gray", marginBottom: 10 }}>Enlace al contenido</Text>
												<View style={{ flexDirection: "row", width: "80%", alignItems: "flex-start", marginTop: 10 }}>
													<TouchableOpacity style={{ height: 40, width: 170, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center" }}>
														<Text style={{ fontSize: 18, border: 50, color: "white" }} onPress={() => Linking.openURL(sectionContents[currentContent]?.link)}>
															{"Enlace"}
														</Text>
													</TouchableOpacity>
												</View>
											</>
										)}

										{sectionContents[currentContent]?.contentType === "AUDIO" && (
											<MediaViewerManager
												uploadData={sectionContents[currentContent]?.contentType}
												useNativeControls={true}
												maxHeight={600}
												type={sectionContents[currentContent]?.contentType}
												url={sectionContents[currentContent]?.media}
												cover={sectionContents[currentContent]?.image}
												videoProps={{ onPlaybackStatusUpdate: (ev) => console.log(ev) }}
											/>
										)}

										{sectionContents[currentContent]?.contentType === "UPLOAD" && (
											<MediaViewerManager
												uploadType={sectionContents[currentContent]?.contentDetails}
												uploadData={sectionContents[currentContent]?.contentType ? uploadData : ""}
												useNativeControls={true}
												maxHeight={600}
												type={sectionContents[currentContent]?.contentType}
												url={sectionContents[currentContent]?.contentType === "EMBEDDED" ? sectionContents[currentContent]?.embedded : sectionContents[currentContent]?.media}
												cover={sectionContents[currentContent]?.image}
												videoProps={{ onPlaybackStatusUpdate: (ev) => console.log(ev) }}
											/>
										)}

										{sectionContents[currentContent]?.contentType === "FORUM" && (
											<View>
												<Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>Detalles</Text>
												<View style={{ marginLeft: 10, marginTop: 10 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 16, color: "gray", marginBottom: 10 }}>{sectionContents[currentContent]?.topic?.title}</Text>
													<TouchableOpacity
														onPress={() => navigation.navigate("StackForum", { screen: "SingleTopic", params: { id: sectionContents[currentContent]?.topic.id, forumID: sectionContents[currentContent]?.topic?.forum?.id } })}
														style={{ height: 40, width: 170, marginRight: 10, alignItems: "center", borderRadius: 7, backgroundColor: "#afd6e2", justifyContent: "center" }}>
														<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Ir al Foro</Text>
													</TouchableOpacity>
												</View>
											</View>
										)}

										{sectionContents[currentContent]?.contentType === "TEST" && (
											<View style={{ backgroundColor: "white", minHeight: 300, borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }}>
												<TestMission data={testContent} loader={loading} responseCallback={(response) => setUserResponse(response)} userResponse={userResponse} />
											</View>
										)}

										{(sectionContents[currentContent]?.contentType === "VIDEO" || sectionContents[currentContent]?.contentType === "EMBEDDED" || sectionContents[currentContent]?.contentType === "AUDIO") && (
											<>
												<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
													<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray", marginRight: 10 }}>Progreso</Text>
													<View style={{ flex: 1 }}>
														<LinearBarIndicator backgroundColor="gainsboro" width={"100%"} rounded={8} height={6} gradient={["#B4EC51", "#429321"]} percentage={10} />
													</View>
												</View>
											</>
										)}
									</View>

									{/* {(sectionContents[currentContent]?.contentType === "EXAM" || sectionContents[currentContent]?.contentType === "TEST") && (
								<View>
									<Text style={{ fontFamily: "DemiBold", fontSize: 20, color: "gray", marginBottom: 20 }}>{activeActivity.contents[0]?.name}</Text>
									<View style={{ height: 520 }}>
										<Exam route={route} navigation={navigation} exID={sectionContents[currentContent]?.id} advanceData={sectionContents} />
									</View>
								</View>
							)} */}
								</View>
							</View>
						</ScrollView>
						<LinearGradient colors={["transparent", "#f8f8fe"]} style={{ position: "absolute", height: 60, bottom: 0, left: 0, right: 0, width: "100%", zIndex: 2 }} />
					</View>
					<View style={{ flexDirection: "row", alignItems: "center", paddingHorizontal: 10, justifyContent: "space-between", width: "100%", height: 60 }}>
						<View style={{ height: 40 }}>
							{sectionContents.length > 1 && currentContent > 0 && (
								<TouchableOpacity style={{ position: "absolute", left: 0, height: 40, width: 170, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center" }} onPress={() => setCurrentContent((currentContent) => currentContent - 1)}>
									<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>{"Anterior"}</Text>
								</TouchableOpacity>
							)}
						</View>
						<TouchableOpacity style={{ height: 40, width: 170, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center" }} onPress={() => nextContent()}>
							<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>{sectionContents.length - 1 === currentContent ? "Completar" : "Siguiente"}</Text>
						</TouchableOpacity>
					</View>
				</View>
			) : (
				<View style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
					<ActivityIndicator size={"large"} />
				</View>
			)}
		</View>
	);
};

export default ActivityGroup;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
