import React, { useState, useEffect, useContext } from "react";
import { View, Text, Image, StyleSheet, SafeAreaView, TouchableOpacity, ScrollView, ActivityIndicator } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import ImageRel from "../../components/common/ImageRel";
import moment from "moment";
import Header from "../../components/AppHeader";

import firebase from "firebase/app";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/common/Button";

const SingleForum = ({ id, navigation, route }) => {
	const { customization, userData, tenant } = useContext(AuthContext);

	const foroID = route.params?.id;
	const foroName = route.params?.foroName;

	const [refreshing, refetching] = useState(true);
	const { navigate } = useNavigation();
	const [loadingMore, setLoadingMore] = useState(false);
	const [isMoreContent, setIsMoreContent] = useState(true);
	const [limit, setLimit] = useState(4);
	const [data, setData] = useState([]);
	const [forumData, setForumData] = useState({});
	const [loading, setLoading] = useState([]);

	console.log(userData);
	useEffect(() => {
		setLoading(true);
		getForumData();

		const unsubscribe = tenant
			.collection("forums")
			.doc(foroID)
			.collection("topics")
			.orderBy("lastActive", "desc")
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const userimg = doc.data().user.image;
						const userimageurl = userimg
							? await firebase
									.storage()
									.ref(userimg)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png")
							: "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";

						const user = {
							...doc.data().user,
							image: userimageurl,
						};
						return { id: doc.id, ...doc.data(), user };
					})
				).then((res) => {
					setLoading(false);
					setData(res);
				});
			});

		return () => {
			unsubscribe();
		};
	}, []);

	const getForumData = () => {
		tenant
			.collection("forums")
			.doc(foroID)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					const img = doc.data()?.image;
					const url = img
						? await firebase
								.storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: "";
					setForumData({ ...doc.data(), id: doc.id, image: url });
				}
			});
	};

	useEffect(() => {
		// navigation.setOptions({
		// 	header: ({ scene, previous, navigation }) => {
		// 		return <Header backWhite backCallback={() => navigation.goBack()} backButton={true} newThread={true} newThreadCallback={() => navigate("CreateTopicPage", { id: foroID, foroName })} />;
		// 	},
		// });
	}, []);

	// if (loading)
	//   return (
	//     <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
	//       <ActivityIndicator size="large" />
	//     </View>
	//   );
	if (loading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<View style={{ backgroundColor: "white", flex: 1 }}>
			{/* {Platform.OS === "web" && <HeaderWeb />} */}
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					paddingBottom: 100,
				}}>
				<View>
					<ImageRel
						source={forumData.image}
						background={true}
						style={{
							width: "100%",
							overflow: "hidden",
							alignItems: "center",
						}}>
						<View style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} />
						<View style={{ padding: 20 }}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									fontFamily: "DemiBold",
									zIndex: 100,
									fontSize: 22,
									padding: 20,
								}}>
								{forumData.name}
							</Text>
						</View>
					</ImageRel>
				</View>
				{/* <View style={{ alignItems: "center", paddingHorizontal: 10 }} >
            <TouchableOpacity
              onPress={() =>
                navigate("CreateTopic", {
                  foroID: data.forum && data.forum.id,
                  foroName: data.forum && data.forum.name
                })
              } style={{ ...styles.btn }}
            >
              <Ionicons style={{ marginRight: 10 }} name="md-add-circle" size={30} color="white" />
              <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "white" }}>Crear Nuevo Hilo</Text>
            </TouchableOpacity>
          </View> */}

				<View style={{ maxWidth: 800, width: "100%", alignSelf: "center" }}>
					{data.length === 0 ? 
						<View style={{flex:1, margin:10, minHeight:250, alignItems:"center", justifyContent:"center", backgroundColor:"#f3f3f3", borderRadius:10}}>
							<Text style={{fontSize:20}}>No hay hilos en este foro</Text>
						</View>
						:
					data.map((h, i) => {
						return (
							<TouchableOpacity
								key={"topic-" + h.id}
								onPress={() =>
									navigate("SingleTopic", {
										id: h.id,
										forumID: h.forum.id,
									})
								}>
								<View
									style={{
										...styles.topicBox,
										borderTopWidth: i === 0 ? 1 : 0,
									}}>
									{/* <Image source={{ uri: h.user && h.user.image && (URL.URL_API + h.user.image.url) }} style={styles.imageRedond}/> */}

									<View style={{ flex: 1 }}>
										<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 22, fontFamily: "DemiBold" }}>{h.title}</Text>
											</View>
											<View style={{ width: 50, marginTop: 5 }}>
												<Text style={{ fontSize: 12, textAlign: "right", color: "#aaa" }}>{moment(h.lastActive ? h.lastActive.toDate() : "").fromNow("mm:mm")}</Text>
											</View>
										</View>

										<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
											<View style={{ flexDirection: "row", alignItems: "center", borderRadius: 15, height: 30, paddingRight: 15, backgroundColor: "#f2f2f2" }}>
												<ImageRel source={h.user && h.user.image} style={{ width: 35, height: 35, borderRadius: 18, marginRight: 10 }} />
												<Text style={{ color: "grey", fontFamily: "Regular" }}>
													{h.user.name}
													{/* {moment(h.createdAt).format("DD/MM/YYYY")} */}
												</Text>
											</View>

											<View
												style={{
													marginLeft: 30,
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													backgroundColor: "#f2f2f2",
													height: 30,
													borderRadius: 15,
													paddingHorizontal: 15,
												}}>
												<Ionicons name="chatbox" size={15} color="gray" style={{ marginRight: 5 }} />
												<Text style={{ fontSize: 12, fontFamily: "DemiBold" }}>{h.responses}</Text>
											</View>
										</View>
									</View>
								</View>
							</TouchableOpacity>
						);
					})}

					{/* {
						isMoreContent && (
							<Button
								loading={loadingMore}
								label="Más Hilos"
								color={customization.mainColor}
								width="95%"
								style={{ alignSelf: "center", marginTop: 20 }}
								onPress={() => {
									setLoadingMore(true);
									setLimit(limit + 4);
								}}
							/>
						)
						// <View style={{ marginTop: 35, marginBottom: 15, alignItems: "center" }}>
						//   <TouchableOpacity onPress={() => {

						//     setLoadingMore(true)
						//     setLimit(limit + 4)

						//   }} style={{ alignItems: "center", justifyContent: "center", height: 50, width: 250, borderRadius: 25, backgroundColor: customization.buttonMainColor }}>
						//     {!loadingMore ?
						//       <Text style={{ color: customization.buttonMainColorText, fontFamily: "DemiBold", fontSize: 16 }}>Mas Hilos</Text>
						//       :
						//       <ActivityIndicator color={"white"} />
						//     }
						//   </TouchableOpacity>
						// </View>
						// :
						// <View style={{ marginTop: 35, marginBottom: 15, alignItems: "center" }}>
						//   <Text style={{ color: "gray", fontFamily: "DemiBold", fontSize: 16 }}>No hay mas Hilos</Text>
						// </View>
					} */}
				</View>
			</ScrollView>
		</View>
	);
};

const styles = {
	topicBox: {
		width: "100%",
		padding: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "gainsboro",
	},
	imageRedond: {
		height: 30,
		width: 30,
		borderRadius: 15,
		borderWidth: 1,
		borderColor: "gainsboro",
		marginRight: 10,
	},
	btn: {
		width: "100%",
		height: 50,
		flexDirection: "row",
		backgroundColor: "#017676",
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
};
export default SingleForum;
